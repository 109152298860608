<template>
	<div>
		<v-flex xs12 lg12 sm12 xl12 class="text-center">
			<img :src="banner" style="width:10%">
		</v-flex>
		<!-- <h1 class="text-center"> {{ $store.getters.store.store_name }} </h1> -->
		<div class="mt-5">
			<v-row>
				<v-col cols="4" sm="4" md="4" lg="4" xl="4">
					<p style="font-size: 15px;" class="mb-1"> {{ $store.getters.language.data.invoices.invoice }}: {{ invoice.invoice_id }} </p>
				</v-col>
				<v-col cols="4" sm="4" md="4" lg="4" xl="4">
					<p style="font-size: 15px;" class="mb-1"> {{ $store.getters.language.data.invoices.customer_name }}: {{ invoice.customer_name }} </p>
				</v-col>
				<v-col cols="4" sm="4" md="4" lg="4" xl="4" class="text-left">
					<p style="font-size: 15px;" class="mb-1"> {{ $store.getters.language.data.invoices.invoice_date }}: {{ new Date(invoice.invoice_date).toISOString().split('T')[0] }} </p>
				</v-col>
			</v-row>

			<p style="font-size: 15px;" class="mb-1"> {{ $store.getters.language.data.invoices.seller }}: {{ invoice.user_name }} </p>
		</div>
		<div class="mt-3">
			<table style="border: 1px solid; border-collapse: collapse; width: 100%; text-align: center;">
				<thead>
					<tr>
						<th style="border: 1px solid;"> {{ $store.getters.language.data.invoice_items.item }} </th>
						<th style="border: 1px solid;"> {{ $store.getters.language.data.invoice_items.price }} </th>
						<th style="border: 1px solid;"> {{ $store.getters.language.data.invoice_items.qty }} </th>
						<th style="border: 1px solid;"> {{ $store.getters.language.data.total }} </th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in invoice_items" :key="item.invoice_item_id">
						<td style="border: 1px solid;"> {{ item.item_name }} </td>
						<td style="border: 1px solid;"> {{ (item.invoice_item_sell_price).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
						<td style="border: 1px solid;"> {{ item.invoice_item_qty }} </td>
						<td style="border: 1px solid;"> {{ (item.invoice_item_qty * item.invoice_item_sell_price).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="mt-2">
			<h3 class="text-center"> {{ $store.getters.language.data.total }} : {{ (invoice.total_invoice).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}</h3>
		</div>
		<!-- print in the buttom of a4 page -->
	</div>
</template>

<script>
	import requests from '../../requests/invoices.request'
	export default {
		data() {
			return {
				invoice: {
					invoice_id: '',
					invoice_date: '',
					invoice_seller: '',
					total_invoice: 0
				},
				invoice_items: [],
				banner: require('../../assets/wasl.jpg'),

			}
		},
		mounted() {
			requests.searchToInvoice(this.$route.params.id).then(r => {
				this.invoice = r.data.invoice;
				this.invoice_items = r.data.invoice_items;
				var darkTheme = this.$vuetify.theme.dark
				if (darkTheme) {
					this.$vuetify.theme.dark = false;
				}
				setTimeout(() => {
					window.print()
					if (darkTheme) {
						this.$vuetify.theme.dark = true;
					}
					window.close()
				}, 500);
			})
		}
	}
</script>

